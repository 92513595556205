<template>
  <Head />
  <a
    href="#C"
    title="中央內容區塊，為本頁主要內容區"
    id="C"
    accesskey="C"
    name="C"
    class="visually-hidden-focusable"
    >:::</a
  >
  <PageBanner />
  <div
    class="container-fluid ps-0 pe-0 pt-3 pb-3 pt-lg-4 pb-lg-5 d-block position-relative"
    style="z-index: 10"
  >
    <div class="container">
      <div class="row g-2 g-lg-4">
        <div
          class="container pt-4 pt-lg-3 pb-lg-3 mt-3 rounded d-flex flex-column justify-content-center"
        >
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb border border-primary rounded p-3">
              <li class="breadcrumb-item"><a href="/">首頁</a></li>
              <li class="breadcrumb-item active" aria-current="page">教學觀摩</li>
            </ol>
          </nav>
          <div class="col-12">
            <div
              class="d-flex flex-row justify-content-between pb-0 section-title mb-2 mb-lg-4"
            >
              <h2 class="fw-bold d-flex align-items-center rounded-top pt-3 pb-3">
                <i
                  aria-hidden="true"
                  class="fa-solid fa-fw fa-cube me-3 font-1-5em"
                  style="color: #df592e"
                ></i
                ><span class="text-dark font-1-2em fw-bold">教學觀摩</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <h3 class="pt-2 pb-2 fw-bold">
        <i class="fa-solid fa-ellipsis-vertical me-3 text-primary"></i>活動說明
      </h3>
      <p class="lh-lg font-1-2em">
        本活動為「《蕃人觀光日誌》數位教材製作與應用計畫」之分項計畫
        3：教材試教與推廣應用。本計畫結合新北市立樟樹國際實創高級中等學校教師與學生進行教材審議與推廣利用，其中包含共同參與教案設計、編製學習手冊、教材與學習單、測驗卷、進行試教與教學觀摩，於試教課程實施前測與後測，執行質量化分析以評量教材品質，同時規劃翻轉教學設計，期能運用於社會領域教學中。
      </p>
      <h3 class="pt-2 pb-2 fw-bold">
        <i class="fa-solid fa-ellipsis-vertical me-3 text-primary"></i>活動時間
      </h3>
      <p class="lh-lg font-1-2em">112年10月19日(星期四) 13：00~15：30PM</p>
      <h3 class="pt-2 pb-2 fw-bold">
        <i class="fa-solid fa-ellipsis-vertical me-3 text-primary"></i>活動地點
      </h3>
      <p class="lh-lg font-1-2em">
        新北市立樟樹國際實創高級中等學校(新北市汐止區樟樹二路135號信義樓1樓)
      </p>
      <h3 class="pt-2 pb-2 fw-bold">
        <i class="fa-solid fa-ellipsis-vertical me-3 text-primary"></i>參與貴賓
      </h3>
      <p class="lh-lg font-1-2em">
        <strong>教育部：</strong><br />
        終身教育司林映如<br />
        <strong>國立臺灣圖書館：</strong><br />
        編審周瑞坤、研究助理張谷源<br />
        <strong>東南科技大學：</strong><br />
        研發長楊靖宇、副教授詹雅能、助理教授張美春、副教授林惠娟、副教授詹瑋、助理教授林文仁<br />
        <strong>新北市立樟樹國際實創高級中等學校：</strong
        ><br />校長陳浩然、教務主任蔡依倫、設備組長湯凱任、教學組長吳忞蓉、社會科老師余昌航、多媒體動畫科科主任柯采妙、徐薏筑老師、李靜宣老師<br />
        多媒體動畫科6位學生、原住民社團16位學生、707班23位學生
      </p>
      <h3 class="pt-2 pb-2 fw-bold">
        <i class="fa-solid fa-camera-retro me-3 text-primary"></i>階段一 –
        共同備課暨主計畫成果展示
      </h3>
      <p class="lh-lg font-1-2em">
        <strong>共同備課內涵</strong><br />
        正式執行教學觀摩前，東南科大計畫案團隊與樟樹國際實創高中老師們先進行了共同備課的教材討論與交流會議，老師們熱烈地討論課程內容與教學目標，希望透過數位教材提高學生學習樂趣，進而達到最佳的學習效果。<br />
        <strong>主計畫成果展示</strong><br />
        教學觀摩活動當日，以本計畫案「《蕃人觀光日誌》數位教材製作與應用計畫」成果發表作為活動開場，包含計畫主持人東南科大研發長楊靖宇說明計畫內涵，樟樹國際實創高中校長陳浩然說明計畫特色，與計畫案人文組召集人詹雅能老師分享動畫學習單設計背景等。接著由樟樹國際實創高中多媒體動畫科6位學生分享本計劃案之衍生設計「原民牌牌讚」撲克牌設計作品。
      </p>
      <div class="row mt-2 mb-5 g-2">
        <div class="col-lg-4" v-for="index in 13" :key="index">
          <div class="card border-0 rounded-0 loading">
            <div class="post_img border d-flex justify-content-center align-items-center">
              <a :href="getPhoto(index, '1')" target="_blank">
                <img
                  :src="getPhoto(index, '1')"
                  class="card-img-top rounded-0"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <h3 class="pt-2 pb-2 fw-bold">
        <i class="fa-solid fa-camera-retro me-3 text-primary"></i>階段二 –
        教學觀摩與實際觀課
      </h3>
      <p class="lh-lg font-1-2em">
        <strong>教學觀摩說明</strong><br />
        本計畫案團隊於新北市立樟樹國際實創高級中等學校執行教學觀摩試教現場。依據教育部十二年國民基本教育課程綱要，針對社會參與核心素養中多元文化與國際理解等項目，由林惠娟老師於試教現場舉辦教學觀摩，邀請新北市高中職、國中、國小教師，以及原住民學生一起參與。<br />
        <strong>共同觀課原則</strong><br />
        試教班級為707班，共23位學生，分為6組，一組3~4人。<br />
        每組小桌各有一位觀課老師實際觀課與紀錄學生學習反應。<br />
        觀課重點為課程活動中學生學習的積極度、專心度與互動參與度等。
      </p>
      <div class="row mt-2 mb-5 g-2">
        <div class="col-lg-4" v-for="index in 28" :key="index">
          <div class="card border-0 rounded-0 loading">
            <div class="post_img border d-flex justify-content-center align-items-center">
              <a :href="getPhoto(index, '2')" target="_blank">
                <img
                  :src="getPhoto(index, '2')"
                  class="card-img-top rounded-0"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <h3 class="pt-2 pb-2 fw-bold">
        <i class="fa-solid fa-camera-retro me-3 text-primary"></i>階段三 – 課程檢討與建議
      </h3>
      <p class="lh-lg font-1-2em">
        此階段為針對本計劃案之數位教材在試教課程中學生學習狀態之反饋與討論。<br />
        教育部林映如小姐、國臺圖周瑞坤先生、張谷源先生分享數位教材於教學現場成功地呈現學生專心投入課程的狀態。<br />
        東南科大研發長楊靖宇、詹雅能副教授、林惠娟副教授、樟樹高中校長陳浩然與6位觀課老師東南科大詹瑋副教授、張美春助理教授、林文仁助理教授、樟樹高中教務主任蔡依倫、教學組長吳忞蓉、社會科老師余昌航分別提出試教課程的觀察結果，同時探討本數位教材扮演了活化歷史之重要性，期能成為未來十二年國教重要之補充教材。
      </p>
      <div class="row mt-2 mb-5 g-2">
        <div class="col-lg-4" v-for="index in 8" :key="index">
          <div class="card border-0 rounded-0 loading">
            <div class="post_img border d-flex justify-content-center align-items-center">
              <a :href="getPhoto(index, '3')" target="_blank">
                <img
                  :src="getPhoto(index, '3')"
                  class="card-img-top rounded-0"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <h3 class="pt-2 pb-2 fw-bold">
        <i class="fa-solid fa-camera-retro me-3 text-primary"></i>活動花絮
      </h3>
      <div class="row mt-2 mb-5 g-2">
        <div class="col-lg-4" v-for="index in 9" :key="index">
          <div class="card border-0 rounded-0 loading">
            <div class="post_img border d-flex justify-content-center align-items-center">
              <a :href="getPhoto(index, '4')" target="_blank">
                <img
                  :src="getPhoto(index, '4')"
                  class="card-img-top rounded-0"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Foot />
</template>

<script>
import Head from "@/components/layout/Head.vue";
import Foot from "@/components/layout/Foot.vue";
import PageBanner from "@/components/PageBanner.vue";

export default {
  components: {
    Head,
    Foot,
    PageBanner,
  },
  methods: {
    getPhoto(index, step) {
      return "/files/teaching_observation/s" + step + "/S (" + index + ").jpg";
    },
  },
  watch: {
    $route() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style scoped>
.loading {
  background-image: url("/images/home/loading.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
}
</style>
